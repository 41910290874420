<template>
  <div class="view_warehouse mt-2">
    <!-- <div>
      <p class="mb-0 p-2" style="color:#767676; font-family:proxima-nova, sans-serif; font-size:14px">
        Warehouses  >
      </p>
    </div> -->
    <!-- <span class="ml-2 summary_refresh_text"
        >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
      >&nbsp;&nbsp;<span class="summary_refresh">
        <img
          style="cursor:pointer"
          @click="loadData()"
          src="../../assets/refresh.png"
          width="15px"
        />
      </span> -->
      <div class="dashboard-filter">
        <b-card class="m-2 mt-4 desktop-filter" v-if="isDesktopFilterOpen">
            <img
              class="mt-3 close-filter"
              src="../../assets/CloseModel.svg"
              width="15px"
              style="cursor:pointer"
              @click="closeDesktopFilter"
            />
          <div class="row">
            <div class="col-md-3 filter-type">
              <label class="mr-2">Status</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="statusList"
                @selectedOption="onSelectStatus($event)"
              />
            </div>

            <div class="col-md-3 filter-type">
              <label class="mr-2">Type</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="warehouseList"
                @selectedOption="onSelectType($event)"
              />
            </div>

            <div class="col-md-3 filter-type">
              <label class="mr-2">Manager</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="managerListOptions"
                @selectedOption="onSelectManager($event)"
              />
            </div>
            <div class="col-md-3 mt-3 text-right">
              <!-- <w-button-input
                :buttonClass="'auth-button'"
                :buttonStyle="'auth-button-style'"
                @buttonClicked="loginButton()"
                :disabled="isloginDetail"
                :label="'Filter'"
              /> -->
              <!-- <button class="clear_filter ml-4">Clear Filter</button> -->
            </div>
          </div>
          <!-- <img
            class="mt-3 close-filter"
            src="../../assets/CloseModel.svg"
            width="15px"
            style="cursor: pointer"
            @click="closeDesktopFilter"
          /> -->
        </b-card>
      </div>
    <div class="warehouse_filter">
      <div class="row justify-content-between">
        <div class="my-4 py-0 px-4" @click="openDesktop_filter">
          <img
            src="../../assets/SVG/desktopFilter.svg"
            style="cursor: pointer"
          />
          <span style="color: #2e8cd3; font-size: 14px; cursor: pointer"
            >Filters</span
          >
        </div>
        <div class="my-4 py-0  pr-4" v-if="isPermission('allow_add_new_warehouse')">
          <img
            src="../../assets/add.png"
            style="cursor: pointer"
            class="mr-1"
          />
          <span
            @click="goToAddWarehouse()"
            style="color: #2e8cd3; font-size: 14px; cursor: pointer"
            >Add New Warehouse</span
          >
        </div>
      </div>
      <!-- filter for mobile -->
      <div class="open-mobile-filter" v-if="isMobile_filter_open">
        <div class="row mx-2">
          <div class="col-2 pt-4">
            <span @click="closeApplyFilter">
              <img src="../../assets/SVG/arrow-back.svg" />
            </span>
          </div>
          <div
            class="col-8 pt-0 d-flex justify-content-center"
            style="
              border-bottom-left-radius: 50%;
              border-bottom-right-radius: 50%;
            "
          >
            <span
              class="text-white bg-dark py-3 px-4"
              style="
                font-size: 20px;
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 50%;
              "
              >LOGO</span
            >
          </div>
          <div class="col-2"></div>
        </div>

        <b-card class="mx-2 mt-5">
          <div class="row">
            <div class="col-md-3 filter-type">
              <label class="mr-2">Status</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="statusList"
                @selectedOption="onSelectStatus($event)"
              />
            </div>

            <div class="col-md-3 filter-type">
              <label class="mr-2">Type</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="warehouseList"
                @selectedOption="onSelectType($event)"
              />
            </div>

            <div class="col-md-3 filter-type">
              <label class="mr-2">Manager</label>
              <w-dropdown
                :label-text="''"
                :selectedValue="null"
                :selectOption="managerListOptions"
                @selectedOption="onSelectManager($event)"
              />
            </div>
            <div class="col-md-3 mt-3 text-right">
              <!-- <w-button-input
                :buttonClass="'auth-button'"
                :buttonStyle="'auth-button-style'"
                @buttonClicked="loginButton()"
                :disabled="isloginDetail"
                :label="'Filter'"
              /> -->
              <button class="clear_filter ml-4">Clear Filter</button>
            </div>
          </div>
        </b-card>
      </div>
      <!-- mobile filter end -->

      <div>
        <!-- filter button for mobile -->
        <!-- <div class="mobile-filter">
          <div class="text-right">
            <p class="mb-0 mx-2 text-primary">
              <span class="apply-filter" @click="onApplyFilter">
                <img src="../../assets/SVG/filter-results-button.svg" />
              </span>
            </p>
          </div>
        </div> -->
        <!-- end -->

        
      </div>
    </div>
    <!-- <b-card class="cards m-2 warehouse_table"> -->
      <!-- <div class="row">
        <span class="col text-right">
          <button
            @click="goToAddWarehouse()"
            class="add_warehouse mr-3"
            >Add New Warehouse</button
          >
        </span>
      </div> -->
      <w-table
        class="warehouse-table"
        :tableLoading="tableLoading"
        :tableData="tabledata"
        @editRowDataEvent="editWarehouse($event)"
        @deleteRowDataEvent="openConfirmationModal($event)"
      ></w-table>
    <!-- </b-card> -->

    <w-delete-confirm-modal
      :msg="selectedWarehouseName"
      @deleteData="deleteWarehouse($event)"
    ></w-delete-confirm-modal>

    <!-- modal for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import Table from "../../widgets/Table.vue";
import Modal from "../../widgets/ModalWidget";
import ButtonInput from "../../widgets/InputButton.vue";
import { EventBus } from "@/main.js";
import DeleteConfirmModal from "../../widgets/DeleteConfirmModal.vue";
import moment from "moment";
import {
  getWarehouseStatus,
  getWarehouseTypeApi,
  getWarehouseWithParams,
  getWarehouse,
  deleteWarehouse,
  getManagerList,
} from "../../Service/WarehouseServiceApi";
import { isAuthorized } from "../../Service/CommonService";

export default {
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-table": Table,
    Modal,
    "w-delete-confirm-modal": DeleteConfirmModal,
    "w-button-input": ButtonInput,
  },

  data() {
    return {
      isDesktopFilterOpen: false,
      isMobile_filter_open: false,
      companyID: sessionStorage.getItem("companyIds"),
      // for loader
      tableLoading: false,
      // for error handling message
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      selectedStatus: "",
      selectedType: "",
      selectedManager: "",

      address: [],
      selecteddType: [],
      warehouseList: [],
      marketPlaceOptions: [],
      selectedWarehouseName: "",
      selectedWarehouseId: "",

      statusList: [],
      managerListOptions: [],

      tabledata: {
        items: [],
        fields: [
          {
            key: "name",
            label: "Name",
          },
          {
            key: "type",
            label: "Type",
          },
          {
            key: "address",
            label: "Address",
          },
          {
            key: "manager",
            label: "Manager",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "warehouse_action",
            label: "Action",
          },
        ],
      },
      lastUpdated: "",
    };
  },

  created() {
    // this.getTable();
    this.getManagerList();
    this.getWarehouseStatus();
    this.getWarehouseType();
    this.loadData();
  },
  mounted() {
    EventBus.$emit("AddWarehouse", () => {
      this.getTable();
    });
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    
    loadData() {
      this.getLastUpdated();
      this.getTable();
    },
    // To get  last updated time
    getLastUpdated() {
      this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    },

    // get the list of managers
    getManagerList(){
      this.managerListOptions=[];
      this.managerListOptions.push({
        value: null,
        text: "Select Manager",
      });
      getManagerList()
      .then((res) => {
        let resp = res || [];
        
        resp.forEach((el) => {
          this.managerListOptions.push({
            text:`${el.firstName} ${el.lastName}`,
            value:el.id
          })
        })
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },

    getWarehouseStatus() {
      this.statusList = [];
      var status = [];
      this.statusList.push({
        value: null,
        text: "All",
      });
      getWarehouseStatus()
        .then((res) => {
          status = res;
          status.map((element) => {
            this.statusList.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getWarehouseType() {
      getWarehouseTypeApi()
        .then((data) => {
          const defaultValue = {
            value: null,
            text: "All",
          };
          const typeList = data.map((element) => {
            return { value: element, text: element };
          });
          this.warehouseList = [defaultValue, ...typeList];
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getTableDataWithParams(data) {
      this.tabledata.items = [];

      this.selectedStatus != "All"
        ? getWarehouseWithParams(
            this.selectedStatus,
            this.selectedType,
            this.selectedManager,
            this.companyID
          )
            .then((data) => {
              this.pushData(data.content);
            })
            .catch((err) => {
              this.alert = true;
              this.alertMsg = err.message;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            })
        : this.getTable();
    },

    onSelectStatus(event) {
      this.selectedStatus = event;
      this.getTableDataWithParams();
    },

    onSelectManager(event) {
      this.selectedManager = event;
      this.getTableDataWithParams();
    },

    onSelectType(event) {
      this.selectedType = event;
      this.getTableDataWithParams();
    },

    pushData(data) {
      // this.marketPlaceOptions = [];
      data.map((element) => {
        // if(element.warehouseType === "MARKETPLACE") {
        //     this.marketPlaceOptions.push({
        //     text:element.warehouseName,
        //     value:element.warehouseId
        //   })
        // }

        // let managerr = "";
        // this.managerListOptions.forEach((ele) => {
        //   if (element.warehouseManagerId === ele.value) {
        //     managerr = ele.text;
        //   }
        // });

        this.tabledata.items.push({
          id: element.warehouseId,
          name: element.warehouseName,
          type: element.warehouseType,
          address:
            element.addressLine1 +
            ", " +
            element.addressLine2 +
            ", " +
            element.city +
            ", " +
            element.state +
            ", " +
            element.country +
            ", " +
            element.pinCode,
          manager: element.managerName,
          status: element.warehouseStatus,
        });
      });
    },
    getTable() {
      this.tabledata.items = [];
      (this.tableLoading = true),
        getWarehouse(this.companyID)
          .then((res) => {
            this.tableLoading = false;
            this.pushData(res.content);
          })
          .catch((err) => {
            this.tableLoading = false;
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
    },
    // for edit and add warehouse page routing
    goToAddWarehouse() {
      this.$router.push({ path: "/warehouse/" + "new" });
    },

    editWarehouse(data) {
      this.$router.push({ path: "/warehouse/" + data.id });
    },

    openConfirmationModal(data) {
      this.$bvModal.show("delete-confirm-pop-modal");
      this.selectedWarehouseId = data.id;
      this.selectedWarehouseName = data.name;
    },
    deleteWarehouse(isDelete) {
      if (isDelete) {
        deleteWarehouse(this.selectedWarehouseId, this.companyID).then(
          (res) => {
            this.getTable();
          }
        );
      }
      this.$bvModal.hide("delete-confirm-pop-modal");
    },

    // close confirmation modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // open filter for mobile
    onApplyFilter() {
      this.isMobile_filter_open = true;
    },
    closeApplyFilter() {
      this.isMobile_filter_open = false;
    },
    // for open a desktop filter
    openDesktop_filter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter() {
      this.isDesktopFilterOpen = false;
    },
  },
};
</script>

<style scoped>
.warehouse_filter ::v-deep .form-control {
  height: 35px !important;
}
.warehouse_filter {
  margin-top: 0 !important;
}
.filter-type {
  padding-top: 8px;
}
.auth-button {
  max-width: 7.5rem !important;
  padding: 0.4rem !important;
}
.clear_filter {
  font: normal normal normal 14px/17px Roboto;
  color: #2185d0;
}
.view_warehouse {
  margin: 0rem 47px 40px 60px;
}
.warehouse_filter label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
}
.warehouse_filter .enter-channel-name ::v-deep .input-group .form-control {
  opacity: 0.6;
  color: #555 !important;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
}
.warehouse_filter ::placeholder {
  opacity: 0.6;
  color: #555;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
}

.add_warehouse {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 6px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 30px;
  border-radius: 2px;
}
/* .warehouse_table ::v-deep .table thead tr th {
  padding-left: 20px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  text-align: center;
}
.warehouse_table ::v-deep .table thead tr th:first-child {
  text-align: left;
}
.warehouse_table ::v-deep .table tbody tr td:first-child {
  text-align: left;
}
.warehouse_table ::v-deep .table thead tr th:nth-child(2) {
  text-align: left;
}
.warehouse_table ::v-deep .table tbody tr td:nth-child(2) {
  text-align: left;
}
.warehouse_table ::v-deep .table thead tr th:nth-child(3) {
  text-align: left;
}
.warehouse_table ::v-deep .table tbody tr td:nth-child(3) {
  text-align: left;
}
.warehouse_table ::v-deep .table tbody td:nth-child(1) {
  width: 10%;
}
.warehouse_table ::v-deep .table tbody td:nth-child(4) {
  width: 10%;
}
.warehouse_table ::v-deep .table tbody tr td {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  padding-left: 20px;
  text-align: center;
  white-space: nowrap !important;
} */
.warehouse-table ::v-deep tbody{
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .view_warehouse {
    margin: 0rem 10px;
    margin-top: -50px !important;
  }
}
.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
</style>
