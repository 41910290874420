import { Apihelper } from "./API/apiHelper.js";

const apiHelper = new Apihelper();
const baseUrl = process.env.VUE_APP_WAREHOUSE_SERVER_URL;
const userServiceUrl = process.env.VUE_APP_USER_SERVER_URL;

// get warehouse dropdown status
export const getWarehouseStatus = () => {
    const uri = `${baseUrl}/api/v1/warehouse-status`;
    return apiHelper.get(uri);
}

// get warehouse dropdown type
export const getWarehouseTypeApi = () => {
    const uri = `${baseUrl}/api/v1/warehouse-types`;
    return apiHelper.get(uri);
}

// get warehouse
export const getWarehouse = (companyId) => {
    const uri = `${baseUrl}/api/v1/warehouses?page-no=0&page-size=30`;
    return apiHelper.get(uri);
}

// delete warehouse
export const deleteWarehouse = (id, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/warehouses/` + id;
    return apiHelper.remove(uri);
}

//  for edit get warehouse detail
export const getWarehouseProfileById = (id, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/warehouses/` + id;
    return apiHelper.get(uri)
};

// for warehouse updates
export const updateWarehouse = (data, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/warehouses`;
    return apiHelper.put(uri, data);
};

// for post add new warehouse
export const addWarehouse = (data, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/warehouses`;
    return apiHelper.post(uri, data);
};

// for post add new marketplace warehouse
export const addMarketPlaceWarehouse = (data) => {
    const uri = `${baseUrl}/api/v1/warehouses/link/warehouses-with-company`;
    return apiHelper.post(uri, data);
};


// for add marketplace warehouse dropdown
export const getMarketplaceWarehouse = (data) => {
    const uri = `${baseUrl}/api/v1/warehouses/get/marketplace-warehouses/not-link-with-company`;
    return apiHelper.post(uri, data);
};


// get warehouse by filter (status, type and manager)

export const getWarehouseWithParams = (status, type, manager, companyId) => {
    let uri = `${baseUrl}/api/v1/warehouses?page-no=0&page-size=30`

    if (status) {
        uri = uri.concat('&statuses=', status);
    }
    if (type) {
        uri = uri.concat('&types=', type);
    }
    if (manager) {
        uri = uri.concat('&warehouse-manager-ids=', manager);
    }

    return apiHelper.get(uri);
}
// Api call for manager list dropdown

export const getManagerList = () => {
    let uri = `${userServiceUrl}/v1/users/permissions/allow_edit_warehouse_config,allow_add_new_warehouse`;

    return apiHelper.get(uri);
}










