<template>
  <div>
    <!-- <EditWarehouse/> -->
    <ViewWarehouse @goToAddWarehouse="updatee($event)" />
    <!-- <AddWarehouse
      v-else
      @goToViewWarehouse="updatee()"
      :marketPlaceOptions="marketPlaceOptions"
    /> -->
  </div>
</template>

<script>
import ViewWarehouse from "../components/Warehouses/ViewWarehouse.vue";
// import AddWarehouse from "../components/Warehouses/AddWarehouse.vue";
// import EditWarehouse from "../components/Warehouses/EditWarehouse.vue";
export default {
  components: {
    ViewWarehouse,
    // AddWarehouse,
    // EditWarehouse,
  },
  data() {
    return {
      var1: false,
      marketPlaceOptions: [],
    };
  },
  methods: {
    updatee(event) {
      // console.log(event);
      this.marketPlaceOptions = event;
      // console.log(this.var, "initial");
      this.var1 = !this.var1;
    },
  },
};
</script>

<style scoped></style>
