<template>
  <div class="table_style mt-3">
    <div class="d-flex justify-content-between align-items-center inventory-pagiantion">
      <div class="search-filter"  v-if="searchListingStatus">
        <!-- <img src="../assets/SVG/search.svg"> -->
        <img src="../assets/SVG/desktopFilter.svg" style="cursor:pointer" @click="openFilter"> 
        <span style="color:#2E8CD3; font-size:14px;cursor:pointer;"  @click="openFilter">Filters</span>
      </div>
      
      <div class="row d-flex align-items-center show-page">
        <div class="mr-3" v-if="isListingStatus && isPermission('allow_map_unmapped_listings_in_bulk')">
          <div class="d-flex align-items-center p-3">
            <w-button-input 
              :img="'require'"
              :ImgSrc="'download.svg'"
              :buttonClass="'shipment-buttons'"
              :buttonStyle="'auth-button-style'"
              :label="'Download Mapping Template'"
              @buttonClicked="downloadListingTemplate"
            />
            <w-button-input 
              class="ml-3"
              :img="'require'"
              :ImgSrc="'uploadicon.svg'"
              :buttonClass="'shipment-buttons'"
              :buttonStyle="'auth-button-style'"
              @buttonClicked="goToUploadTemplate"
              :label="'Upload Mapping'"
            />
          </div>
        </div>
        <div>
          <span class="show-class mr-2 ml-auto" v-if="tableData.totalRows >= 5"
            >Show</span
          >
          <b-form-select
            v-if="tableData.totalRows >= 5"
            style="width: 80px; height: 30px; box-shadow: none; font-size: 12px"
            v-model="tableData.perPage"
            :options="getPerPageOptions(tableData.totalRows)"
            @change="getSelectedPerPage($event)"
          ></b-form-select>
        </div>
        <div class="d-flex listing-pagination">
          <span class="show-class ml-1" v-if="tableData.totalRows >= 5"
            >entries</span
          >
          <b-pagination
            v-if="tableData.totalRows >= 5"
            :class="tableData.totalRows >= 5 ? 'pr-3' : 'pr-3 ml-auto'"
            :total-rows="tableData.totalRows"
            :per-page="tableData.perPage"
            :value="tableData.currentPage"
            :limit="1"
            aria-controls="my-table"
            @change="getSelectedPage($event)"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-card>
      <b-table
        :busy="tableLoading"
        thead-class="theadClass"
        id="my-table2"
        :items="tableData.items"
        :class="labelClass"
        :fields="tableData.fields"
        responsive
        sticky-header="200px"
        class="tableStyle"
        style="max-height: 800px; overflow: auto"
        show-empty
        bordered
        :no-border-collapse="true"
      >
        <!-- for loader start -->
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <!-- loader end -->

        <!-- checkbox start -->
        <template v-slot:head(checkbox)="{ item }">
          <label class="checkbox">
            <input
              type="checkbox"
              :key="item"
              v-model="tableData.allSelected"
              :value="tableData.allSelected"
              @change="getCheckedValue()"
            />
            <span class="primary"></span>
          </label>
        </template>
        <template v-slot:cell(checkbox)="{ item }">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="item.selected"
              :key="item"
              @change="getRowCheckedValue()"
            />
            <span class="primary"></span>
          </label>
        </template>
        <!-- checkbox end -->

        <template v-slot:cell(channelName)="data">
          <img :src="getImage('amazon-icon')" style="height: 15px; width: 15px" />
          {{ data.item.channelName }}
        </template>
        <template v-slot:cell(actionn_field)="data">
          <div
            class="d-flex justify-content-center"
            style="margin: 2px; filter: brightness(0)"
          >
            <router-link to="/EditUser">
              <img
                v-if="data.item.type != 'MARKETPLACE'"
                src="@/assets/edit.svg"
                @click="editRowDataEvent(data.item)"
                class="mr-2"
              />
            </router-link>
            <span
              v-if="data.item.type === 'MARKETPLACE'"
              class="mr-3 pr-3"
            ></span>
            <img
              src="@/assets/delete-red.svg"
              @click="deleteRowDataEvent(data.item)"
              class="ml-2"
              style="color: red"
            />
          </div>
        </template>
        <!-- for warehouse action -->
        <template v-slot:cell(warehouse_action)="data">
          <div
            class="d-flex justify-content-center"
            style="margin: 2px; filter: brightness(0)"
          >
            <img
              v-if="data.item.type != 'MARKETPLACE'"
              v-show="isPermission('allow_edit_warehouse_config')"
              src="@/assets/edit.svg"
              @click="editRowDataEvent(data.item)"
              class="mr-2"
              style="cursor: pointer"
            />
            <span
              v-if="data.item.type === 'MARKETPLACE'"
              class="mr-3"
              style="padding-right: 11px"
            ></span>
            <img
              v-show="isPermission('allow_delete_existing_warehouse')"
              src="@/assets/delete.svg"
              @click="deleteRowDataEvent(data.item)"
              class="ml-2"
              style="cursor: pointer"
            />
          </div>
        </template>

        <template v-slot:cell(action_field)="data">
          <div
            class="d-flex justify-content-center"
            style="margin: 2px; filter: brightness(0)"
          >
            <!-- <img src="@/assets/edit.svg" @click="editRowDataEvent(data.item)" class="mr-3" /> -->
            <img
              src="@/assets/transfer.svg"
              @click="resendRowDataEvent(data.item)"
              class="mr-3"
            />
            <img
              src="@/assets/delete-red.svg"
              @click="deleteRowDataEvent(data.item)"
              class="ml-2"
            />
          </div>
        </template>
        <template v-slot:cell(actions_field)="data">
          <div>
            <div v-if="data.item.mappingStatus === 'MAPPED'">
              <!-- <a
                v-b-modal.modal-centerr
                href="#"
                style="display:block; "
                @click="openBundleEvent(data.item)"
              >
                Mark as Bundle
              </a> -->
            </div>
            <div v-if="data.item.mappingStatus === 'UNMAPPED'">
              <div class="d-flex justify-content-between">
                <img src="../assets/SVG/mapping.svg" 
                  @click="openAddModalEvent(data.item)"  
                  v-b-popover.hover.top="'Add Product Mapping'" 
                  v-if="isPermission('allow_map_unmapped_listings')"
                >
                <img src="../assets/SVG/alias.svg"  
                  @click="openAliasModalEvent(data.item)" 
                  v-b-popover.hover.top="'Create Alias'"
                  v-if="isPermission('allow_create_alias')"
                >
              </div>
              <!-- <a
                v-b-modal.modal-centered
                href="#"
                style="display: block"
                @click="openAddModalEvent(data.item)"
                >Add Product Mapping</a
              >
              <a
                v-b-modal.modal-centerred
                href="#"
                style="display: block"
                @click="openAliasModalEvent(data.item)"
                >Create Alias</a
              > -->
            </div>
          </div>
        </template>
        <template v-slot:cell(ac_field)="data">
          <div class="d-flex" style="margin: 2px">
            <button
              class="downloadd"
              style="border: 1px solid #2e8cd3; padding: 5px; color: #2e8cd3"
              @click="downloadCSVEvent(data.item)"
            >
              Download Csv
            </button>
          </div>
        </template>
        <template v-slot:cell(file_field)="data">
          <div class="d-flex" style="margin: 2px">
            <button class="downloadd" @click="downloadCSEvent(data.item)">
              Download
            </button>
          </div>
        </template>
        <template v-slot:cell(title)="data">
          {{ data.item.title }}
        </template>
        <template v-slot:cell(alias)="data">
          <p v-for="alias in data.item.alias" :key="alias">
            {{ alias }}
          </p>
        </template>
        <!-- customize your table  -->
        <template v-slot:cell(revenue)="data">
          <label>
            {{ overViewCardDetails.currency }} {{ data.item.revenue }}
          </label>
        </template>

        <template v-slot:cell(returnedOrders)="data">
          <label>
            {{ data.item.returnedOrders }}
            <span
              >( {{ overViewCardDetails.currency }}
              {{ data.item.returnedRevenue }} )</span
            >
          </label>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { isAuthorized } from "../Service/CommonService";
import ButtonInput from "../widgets/InputButton.vue"
export default {
  components:{
    "w-button-input": ButtonInput,
  },
  props: ["tableData", "tableLoading", "isListingStatus", "searchListingStatus"],
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    
    getPerPageOptions() {
      let options = [];
      options.push({ text: "5", value: 5 });
      for (let index = 5; index < 100; ) {
        index = index + 5;
        options.push({ text: index, value: index });
      }
      return options;
    },
    getSelectedPage(event) {
      this.$emit("getSelectedPageEvent", event);
    },
    getSelectedPerPage(event) {
      this.$emit("getSelectedPerPageEvent", event);
    },
    getImage(image) {
      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + image + ".svg");
    },
    editRowDataEvent(data) {
      this.$emit("editRowDataEvent", data);
    },
    resendRowDataEvent(data) {
      this.$emit("resendRowDataEvent", data);
    },
    deleteRowDataEvent(data) {
      this.$emit("deleteRowDataEvent", data);
    },
    openAddModalEvent(data) {
      this.$emit("openAddModalEvent", data);
    },
    openAliasModalEvent(data) {
      this.$emit("openAliasModalEvent", data);
    },
    openBundleEvent(data) {
      this.$emit("openBundleEvent", data);
    },
    downloadCSVEvent(data) {
      this.$emit("downloadCSVEvent", data);
    },
    // open filter for listing status
    openFilter(){
      this.$emit("openFilterEvent")
    },

    goToUploadTemplate() {
      this.$emit("goToUploadTemplate");
    },
    downloadListingTemplate() {
      this.$emit("downloadListingTemplate");
    },
    // for checkbox
    getCheckedValue() {
      if (this.tableData.allSelected) {
        this.tableData.items.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.tableData.items.forEach((item) => {
          item.selected = false;
        });
      }
    },
    getRowCheckedValue() {
      this.tableData.allSelected = this.tableData.items.every(
        (item) => item.selected
      );
    },
  },
};
</script>
<style scoped>
::v-deep .table td {
  vertical-align: middle !important;
  padding-right: 16px;
  padding-left: 10px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .td-title-class {
  max-width: 250px;
}
::v-deep .td-status-class {
  max-width: 100px;
}
::v-deep .theadClass {
  background-color: #efefef !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .theadClass {
  padding: 40px !important;
}
.downloadd {
  width: 133px;
  height: 30px;
  color: #2e8cd3;
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #ffffff;
}
a {
  text-decoration: underline;
}
.show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.search-filter{
  color: #2E8CD3;
  font-size: 14px;
  cursor: pointer;
}
.listing-pagination ::v-deep .pagination{
  margin-bottom: 0;
}
.listing-pagination ::v-deep .page-link{
  font-size: 25px ;
  line-height: 0.4 !important;
}
.listing-pagination ::v-deep .active .page-link{
  font-size: 15px ;
  /* line-height: 0 !important; */
}
.show-page ::v-deep .shipment-buttons{
  max-width: 60% !important;
}
</style>
<style>

.table{
  border: 0px !important;
}
.table .thead-light tr {
  background-color: #f4f4f4 !important;
}
.table .thead-light th {
  background-color: transparent;
  border:1px solid transparent;
}
.table tr:first-child td{
  border: 0px;
}
.show-page{
  justify-content: flex-end;
}
.inventory-pagiantion{
  margin-bottom: 10px;
}
@media only screen and (max-width: 555px){
  .inventory-pagiantion{
    flex-direction: column;
    margin-bottom: 20px;
  }
  .search-filter{
    width: 100%;
  }
  .show-page{
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .listing-pagination{
    margin-top: 5px;
  }
}

</style>
